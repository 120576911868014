<template>
  <div class="dashboard-cnt">
    <header>
      <!-- <menuIcon /> -->
      <span class="h_logo">
        <img src="@/assets/images/logo.svg" alt="Ads91" />
      </span>
      <Button className="button" @buttonClick="$router.push('/create-ad-form?intent=LINK_OPEN&MediaType=1')">
      <span class="icon-Btn">
        <img src="@/assets/images/createAdIcon.svg" alt="">
      </span>
        {{$t("Create Ad")}}
      </Button>
    </header>
    <ul class="dashboard-tab">
      <li
        :class="{ 'active-tab': currentTab == 'yourads' }"
        @click="ToggleTab('yourads')"
      >
        {{$t("Your Ads")}}
      </li>
      <li
        :class="{ 'active-tab': currentTab == 'wallet' }"
        @click="ToggleTab('wallet')"
      >
      {{$t("Wallet")}}
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
// import createAdIcon from "../../components/icons/createAdIcon";
import Button from "../../components/AppButton"
export default {
  components: {
    // createAdIcon,
    Button
  },
  data() {
    return {
      currentTab: "yourads",
      loading: true,
      toasterData:{}
    };
  },

  mounted() {
    if(this.$route.path.includes("/wallet")){
      this.currentTab = "wallet";
    }
    if(this.$route.path.includes("/my-ads")){
      this.currentTab = "yourads";
    }
  },

  methods: {
    ToggleTab(tab) {
      this.currentTab = tab;
      tab == 'yourads' ? this.$router.push("/dashboard/my-ads").catch(()=>{}) : this.$router.push("/dashboard/wallet").catch(()=>{});
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/dashboard";
</style>