<template>
  <div>
    <main-header></main-header>
    <!-- <router-view /> -->
    <!-- <main-footer></main-footer> -->
  </div>
</template>

<script>
import MainHeader from "@/layout/main/MainHeader";
// import MainFooter from "@/layout/main/MainFooter";
export default {
  name: "MainLayout",
  components: { MainHeader },
};
</script>

<style scoped></style>
